.header-logo::before{
  display: none;
}

.header__nav li + li:before {
  content: '•';
  display: inline-block;
  margin-right: 0.5rem;
}

.header__nav li + li{
  display: flex;
  margin-left: 0.5rem;
}

@media (max-width: 400px){
  .header__contact{
    flex-direction: column;
  }

  .header__contact a{
    display: block;
  }

  .header__contact a + a{
    margin-top:  1rem;
  }
}

@media (max-width: 550px){
  .header.section__padding{
    padding: 1rem;
  }
}