@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');

@font-face {
  font-family: 'Branch';
  src: url(./assets/fonts/Branch.woff) format("woff"), /* Modern Browsers */
       url(./assets/fonts/Branch.ttf)  format("truetype"), /* Safari, Android, iOS */
       url(./assets/fonts/Branch.svg) format("svg"); /* Legacy iOS */
}


:root {
  --font-family: 'Lato', sans-serif;
  --font-family-head: 'Rufina', serif;
  --font-family-logo: 'Branch', serif;

  --color-secondary-rgb: #dfc2a4;
  --color-tertiary: #e0c2a3;  /* SAND & SKIN */
  --color-quaternary: #684536; /* DARK WOOD */
  --color-quinary: #E8E4D6; /* ASH WHITE */
  
  --color-bg: var(--color-quinary);
  --color-text: #050505;
  --color-text-light: #fff;
  --color-subtext: var(--color-quaternary);
  --color-btn-text: #fff;
  --color-btn-bg: #393637;
}

html{
  font-size: 17px;
}


h1,h2,h3,h4,h5,.font-heading {
  font-family: 'Rufina', serif;
}

p{
  margin-bottom: 20px;
}

.font-logo{
  font-family: var(--font-family-logo);
}

body {
  font-family: var(--font-family);
  background-color: var(--color-bg);
  font-weight: 300;
}

a{
  position: relative;
}

a::before{
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1px;
  left: 0;
  background-color: var(--color-btn-bg);
  transition: width 0.25s ease-in-out ;
}

a:hover::before,
a:active::before,
a:focus::before{
  width: 100%;
}


.btn{
  display: inline-block;
  border: 2px solid var(--color-btn-bg);
  border-radius: 50px;
  background-color: var(--color-btn-bg);
  padding: 0.5rem 2rem;

  font-family: var(--font-family);
  color: var(--color-text-light);
  transition: all 0.25s ease-in ;
}

.btn::before{
  display: none;
}

.btn:hover,
.btn:active,
.btn:focus{
  background-color: var(--color-subtext);
  border-color: var(--color-subtext);
}

.btn-trans{
  background-color: transparent;
  color: var(--color-btn-bg);
}

.btn-trans:hover,
.btn-trans:active,
.btn-trans:focus{
  background-color: var(--color-subtext);
  border-color: var(--color-subtext);
  color: var(--color-text-light);
}


.section__padding{
  padding: 2rem;
}

.section__padding-lx{
  padding: 4rem 2rem;
}

.page-titile-margin{
  margin-bottom: 20px;
}

.focus-container{
  width: 100%;
}


/* headings */
.heading{
  font-size: 26px;
}

.paragraph{
  font-size: 17px;
  font-family: var(--font-family);
}


/* Tablet */

@media (min-width: 768px){
  .section__padding{
    padding: 3rem;
  }

  .small{
    display: none;
  }

  .heading {
    font-size: 36px;
  }

  .paragraph {
    font-size: 19px;
  }
}


@media (min-width: 1024px){
  .section__padding{
    padding: 4rem;
  }


  .section__padding-lx{
    padding: 7rem 4rem;
  }

  .heading {
    font-size: 48px;
  }

  .paragraph {
    font-size: 24px;
  }

  p,a,.btn,span{
    font-size: 19px; 
  }


  .focus-container{
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }

  .why-choose-us h3{
    font-size: 22px;
  }

  .why-choose-us ol{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .why-choose-us ol > li + li {
    margin-bottom: 2rem;
  }
}


/* lx screen */

@media (min-width: 1280px){
  .heading {
    font-size: 52px;
  }

  .page-titile-margin{
    margin-bottom: 20px;
  }

  .paragraph {
    font-size: 36px;
  }

  .why-choose-us ol{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .why-choose-us ol > li + li {
    margin-bottom: 3rem;
  }
}


/* 2lx screen */

@media (min-width: 1536px){
  .section__padding{
    padding: 5rem;
  }

  .section__padding-lx{
    padding: 9rem 5rem;
  }

  .heading {
    font-size: 64px;
  }

  .paragraph {
    font-size: 48px;
  }

  .page-titile-margin{
    margin-bottom: 40px;
  }
}
